// App.account_channel = App.cable.subscriptions.create("AccountChannel", {
import consumer from "./consumer"

consumer.subscriptions.create("AccountChannel", {
  connected() {},

  disconnected() {},

  received(data) {
    if(data.action == 'files_synced' || data.action == 'file_uploaded') {

      if(data.action == 'files_synced') {
        $('.sync_wrapper').html(data.sync_button);
      }


      $('a.sync').css({'opacity' : 1});
      
      $('#file_dropzone i').removeClass('fa-sync-alt fa-spin');
      $('#file_dropzone i').addClass('fa-file-alt');

      if(data.background_job_status == 'failed') {
        $('body').prepend(data.flash_message_fail);
      } else {
        $('body').prepend(data.flash_message_success);
      }

      var tree = $('#tree').fancytree('getTree');
      tree.reload();

    } else if (data.action == 'background_job_failed') {
      // background job has been pending for 1 minute, and fails by default
      
      $('.sync_wrapper').html(data.sync_button);
      $('body').prepend(data.flash_message_fail);

      var tree = $('#tree').fancytree('getTree');
      tree.reload();
    };
  }
});