$(document).on('click', '.subscribe .submit', function() {
    
  email = $(this).closest('.subscribe').find('input').val();
  
  if (email != 'undefined') {
    $.ajax({
      type: 'POST',
      url: '/subscribe',
      dataType: 'script',
      data: {email: email},
      success: function() {
        // $('body').prepend(gon.flash_message);
        $('.subscribe input').val('');

      }
    });
  }
})
